export const CURVES = 'Curves';
export const CURVE_DETAILS = 'Curve details';
export const MARKET_DATA_REPORT = 'Market data lookback report';
export const PAGE_TITLE_PREFIX = 'AT';
export const PRICING = 'Pricing';
export const ASSET_MASTER = 'Asset master';
export const ASSET_DETAILS = 'Asset details';
export const VALUATION_RUNS = 'Valuation runs';
export const VALUATION_RUN_DETAILS = 'Valuation run details';
export const PORTFOLIO_SETUP = 'Portfolio setup';
export const INDEXES = 'Indexes';
export const CLASSIFICATION_RUN_DETAILS = 'Classification run details';
export const PORTFOLIOS = 'Portfolios';
export const SPLIT_FLAGS = 'Split flags';
export const ENTITIES = 'Entities';
export const ORGANIZATIONS = 'Organizations';
export const RISK_RUNS = 'Risk runs';
export const RISK_DETAILS = 'Risk run details';
export const USERS = 'Users';
export const ADD_USER = 'Add user';
export const VALUATION_RANGE_RUNS = 'Valuation range runs';
