import { useAuth0 } from '@auth0/auth0-react';
import { LogoutOptions } from '@auth0/auth0-spa-js';
import { AppFooter } from '@risk-first/ui-app-footer';
import { AppHeader } from '@risk-first/ui-app-header';
import { Loading } from '@risk-first/ui-loading';
import React, { lazy, Suspense, useCallback, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ReactComponent as AdminToolsLogoReactComponent } from '../assets/logos/PFaroeAdminTools.svg';
import { AccessToken } from '../auth/AccessToken';
import { PrivateRoute } from '../lib/private-route';
import { ContentContainer, ContentWrapper, PageLoader } from './DefaultContent.style';
import { Navigation } from './Navigation';

const ValuationRuns = lazy(() => import('../features/valuation-runs'));
const ValuationRangeRuns = lazy(() => import('../features/valuation-runs/ValuationRangeRuns'));
const ValuationRunDetailsPage = lazy(() => import('../features/valuation-runs/run-details/ValuationPage'));
const Indexes = lazy(() => import('../features/indexes/Page'));
const PortfolioSetupPage = lazy(() => import('../features/portfolio-setup/Page'));
const PricingPage = lazy(() => import('../features/pricing/Page'));
const MarketDataReportPage = lazy(() => import('../features/market-data-report/Page'));
const CurveListPage = lazy(() => import('../features/curves/curve-list/Page'));
const CurveDetailsPage = lazy(() => import('../features/curves/curve-details/DetailsPage'));
const AssetMasterPage = lazy(() => import('../features/asset-master/AssetMaster'));
const AssetDetailsPage = lazy(() => import('../features/asset-master/asset-details/AssetDetails'));
const ClassificationRunDetailsPage = lazy(() => import('../features/valuation-runs/run-details/ClassificationPage'));
const PortfoliosPage = lazy(() => import('../features/portfolios/Page'));
const SplitFlagsPage = lazy(() => import('../features/split-flags/Page'));
const SplitDetailPage = lazy(() => import('../features/split-flags/detail/Page'));
const Entities = lazy(() => import('../features/entities/Page'));
const Organizations = lazy(() => import('../features/organizations/Page'));
const OrganizationDetails = lazy(() => import('../features/organizations/details/Page'));
const AddUser = lazy(() => import('../features/users/add-user/Page'));
const Users = lazy(() => import('../features/users/Page'));
const UserDetails = lazy(() => import('../features/users/details/Page'));
const RiskRunDetailsPage = lazy(() => import('../features/valuation-runs/run-details/risk-details/RiskDetailsPage'));
const RiskRunParentPage = lazy(() => import('../features/valuation-runs/run-details/risk-runs/RiskRunParentPage'));

export const DefaultContent = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const logo = React.useMemo(() => <AdminToolsLogoReactComponent width={150} />, []);
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const handleOpenMenuClick = useCallback(() => setSidebarVisibility(!isSidebarVisible), [isSidebarVisible]);

  const handleLogout = useCallback(() => {
    const returnTo = `${window.location.origin}/logout`;
    const logoutOptions: LogoutOptions = {
      returnTo,
    };
    logout(logoutOptions);
  }, [logout]);

  return (
    <>
      <AppHeader
        hideMenuButton
        hideProfileMenu={!isAuthenticated}
        logo={logo}
        user={user ? { name: user?.name ?? '', picture: user?.picture ?? '' } : undefined}
        variant="gradient"
        onLogout={handleLogout}
        onOpenMenuClick={handleOpenMenuClick}
      />
      <Navigation>
        <ContentContainer>
          <ContentWrapper>
            <Suspense
              fallback={
                <PageLoader>
                  <Loading />
                </PageLoader>
              }
            >
              <Switch>
                <PrivateRoute exact component={() => <Redirect to="/curve-list" />} path="/" />
                <PrivateRoute
                  component={() => <CurveDetailsPage isSidebarVisible={isSidebarVisible} />}
                  path="/curve-list/:curveName"
                />
                <PrivateRoute component={CurveListPage} path="/curve-list" />
                <PrivateRoute component={MarketDataReportPage} path="/market-data-report/:marketDataReportId?" />
                <PrivateRoute component={PricingPage} path="/pricing" />
                <PrivateRoute exact component={AssetDetailsPage} path="/asset-master/:id" />
                <PrivateRoute exact component={AssetMasterPage} path="/asset-master" />
                <PrivateRoute component={PortfolioSetupPage} path="/portfolio-setup" />
                <PrivateRoute component={PortfoliosPage} path="/portfolios" />
                <PrivateRoute component={Indexes} path="/indexes" />
                <PrivateRoute component={ValuationRunDetailsPage} path="/valuation-runs/:runId" />
                <PrivateRoute component={ValuationRuns} path="/valuation-runs" />
                <PrivateRoute component={ValuationRangeRuns} path="/valuation-range-runs" />
                <PrivateRoute component={ClassificationRunDetailsPage} path="/classification-runs/:runId" />
                <PrivateRoute component={RiskRunParentPage} path="/risk-runs/:riskRunId" />
                <PrivateRoute component={RiskRunDetailsPage} path="/risk-details/:riskRunId/:valuationRunId" />
                <PrivateRoute component={SplitDetailPage} path="/split-flags/:wsId/:splitName" />
                <PrivateRoute component={SplitFlagsPage} path="/split-flags" />
                <PrivateRoute component={Entities} path="/entities" />
                <PrivateRoute component={OrganizationDetails} path="/organizations/:organizationId" />
                <PrivateRoute component={Organizations} path="/organizations" />
                <PrivateRoute component={AddUser} path="/add-user" />
                <PrivateRoute component={UserDetails} path="/users/:userId" />
                <PrivateRoute component={Users} path="/users" />
              </Switch>
            </Suspense>
          </ContentWrapper>
          <AppFooter variant="light" />
        </ContentContainer>
      </Navigation>
      <AccessToken />
    </>
  );
};
