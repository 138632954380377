import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GlobalFonts } from '../components/GlobalFonts';
import { setUser } from '../lib/monitoring/set-user';
import { useInitRum } from '../lib/monitoring/use-init-rum';
import Callback from '../pages/Callback/Callback';
import Login from '../pages/Login/Login';
import { SplitProvider } from '../split/SplitProvider';
import { theme } from '../theme';
import { Loading } from './App.style';
import { DefaultContent } from './DefaultContent';

const queryClient = new QueryClient();

const App = () => {
  const { isLoading, user } = useAuth0();

  useEffect(() => {
    setUser(user);
  }, [user]);

  useInitRum();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SplitProvider>
        <GlobalFonts />
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route component={Login} path="/login" />
            <Route path="/callback" render={(props) => <Callback {...props} />} />
            <Route component={DefaultContent} />
          </Switch>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </SplitProvider>
    </ThemeProvider>
  );
};

export default App;
