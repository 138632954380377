import {
  IconArrowRangeComponent,
  IconAttachMoneyComponent,
  IconDashboardComponent,
  IconDatabaseComponent,
  IconDataExplorationComponent,
  IconFlagComponent,
  IconFolderCopyComponent,
  IconGroupsComponent,
  IconHistoryComponent,
  IconLayersComponent,
  IconListAltComponent,
  IconManageAccountsComponent,
  IconMultilineChartComponent,
  IconPriceChangeComponent,
  IconRefreshComponent,
  IconRuleSettingsComponent,
  IconStackedLineChartComponent,
  IconWidgetsComponent,
} from '@risk-first/ui-assets';
import * as PageTitles from '../lib/constants/pageTitles';

export const ROUTES = [
  {
    page: 'Asset master and Entities',
    icon: IconAttachMoneyComponent,
    children: [
      {
        page: PageTitles.ASSET_MASTER,
        path: '/asset-master',
        icon: IconLayersComponent,
      },
      {
        page: PageTitles.ENTITIES,
        path: '/entities',
        icon: IconDatabaseComponent,
      },
    ],
  },
  {
    page: 'Valuations',
    icon: IconStackedLineChartComponent,
    children: [
      {
        page: PageTitles.PORTFOLIO_SETUP,
        path: '/portfolio-setup',
        icon: IconRuleSettingsComponent,
      },
      {
        page: PageTitles.PORTFOLIOS,
        path: '/portfolios',
        icon: IconFolderCopyComponent,
      },
      {
        page: PageTitles.VALUATION_RUNS,
        path: '/valuation-runs',
        icon: IconRefreshComponent,
      },
      {
        page: PageTitles.VALUATION_RANGE_RUNS,
        path: '/valuation-range-runs',
        icon: IconArrowRangeComponent,
      },
      {
        page: PageTitles.PRICING,
        path: '/pricing',
        icon: IconPriceChangeComponent,
      },
      {
        page: PageTitles.MARKET_DATA_REPORT,
        path: '/market-data-report',
        icon: IconHistoryComponent,
      },
    ],
  },
  {
    page: 'Market Data',
    icon: IconDataExplorationComponent,
    children: [
      {
        page: PageTitles.CURVES,
        path: '/curve-list',
        icon: IconMultilineChartComponent,
      },
      {
        page: PageTitles.INDEXES,
        path: '/indexes',
        icon: IconListAltComponent,
      },
    ],
  },
  {
    page: 'User management',
    icon: IconManageAccountsComponent,
    children: [
      {
        page: PageTitles.ORGANIZATIONS,
        path: '/organizations',
        icon: IconDashboardComponent,
      },
      {
        page: PageTitles.USERS,
        path: '/users',
        icon: IconGroupsComponent,
      },
    ],
  },
  {
    page: 'Other',
    icon: IconWidgetsComponent,
    children: [
      {
        page: PageTitles.SPLIT_FLAGS,
        path: '/split-flags',
        icon: IconFlagComponent,
      },
    ],
  },
];
